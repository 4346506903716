import { Link } from 'gatsby';
import React, { FC } from 'react';
import * as styles from './button.module.scss';

interface GenericButtonProps {
	type?: 'primary' | 'secondary';
	className?: string;
}

export interface ButtonProps extends GenericButtonProps {
	onClick?: () => void;
}

export const Button: FC<ButtonProps> = ({ children, type = 'primary', onClick, className }) => (
	<button className={`${styles.root} ${styles[type]}` + (className ? ` ${className}` : '')} onClick={onClick}>
		{children}
	</button>
);

export interface LinkButtonProps extends GenericButtonProps {
	url: string;
	isInternal?: boolean;
}

export const LinkButton: FC<LinkButtonProps> = ({ children, className, type = 'primary', url, isInternal = false }) =>
	isInternal ? (
		<Link to={url} className={`${styles.root} ${styles[type]}` + (className ? ` ${className}` : '')}>
			{children}
		</Link>
	) : (
		<a
			href={url}
			className={`${styles.root} ${styles[type]}` + (className ? ` ${className}` : '')}
			target="_blank"
			rel="noopener noreferrer"
		>
			{children}
		</a>
	);

Button.displayName = 'Button';
