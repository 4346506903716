import React, { FC } from 'react';
import { PageProps } from 'gatsby';
import { SEO } from '../components/SEO/SEO';
import { Stage } from '../components/Stage/Stage';
import { ContentConstraint } from '../components/ContentConstraint/ContentConstraint';
import { LinkButton } from '../components/Button/Button';
import { Layout } from '../fragments/Layout/Layout';
import { Configurator } from '../fragments/Configurator/Configurator';

// markup
const NotFoundPage: FC<PageProps> = ({ uri }) => {
	return (
		<Configurator>
			<SEO title="Not found" description="This page was not found" url={uri} />
			<Layout>
				<Stage title="Ooops ..." lead="Sorry, we couldn't find what you were looking for :/" />
				<ContentConstraint>
					<LinkButton isInternal type="secondary" url="/">
						Back Home
					</LinkButton>
				</ContentConstraint>
			</Layout>
		</Configurator>
	);
};

export default NotFoundPage;
